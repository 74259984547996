<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy" class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <!-- a class="footer-link-last" title="TikTok tts Pricing" :href="pricing_href" rel="nofollow">
          {{ $t('comm.pricing') }}
        </a -->
        <span class="footer-link-last">{{ $t('comm.footer_help') }} tiktokaivoicetool@gmail.com</span>
        <a href="#" @click.prevent="showModal = true" class="footer-link-last">Links</a>
      </div>
    </div>

    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>

    <div v-if="showModal" class="modal-overlay" @click="showModal = false">
      <div class="modal-content" @click.stop>
        <button class="close-btn" @click="showModal = false">&times;</button>
        <div class="links-container">
          <a v-for="(link, index) in flattenedLinks"
            :key="index"
            :href="link.href"
            :title="link.text"
            target="_blank"
            rel="noopener noreferrer"
            class="friend-link">
            {{ link.text }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data() {
    return {
      showModal: false,
      pricing_href: '/en/pricing',
      footerColumns: [
        [
          { href: "https://podcastindex.org/podcast/7113014", text:"TikTok tts"},
          { href: "https://www.similarweb.com/website/tiktokvoice.net/", text: "tiktok voice generator" },
          { href: "https://dreevoo.com/forum_post.php?idt=625&r=1177551091#new", text: "AI Sound Effect Generator"},
          { href: "https://aivoicelab.net", text: "AIVoiceLab" },
          { href: "https://chatgpt.com/share/6773727d-2694-8011-beb6-bd2aef5abceb", text: "TikTok Voice Generator" },
          { href: "https://www.genspark.ai/search?query=why+https://tiktokvoice.net/++is+the+best+TikTok+Voice+Generator", text: "TikTok tts" },
          { href: "https://hailuoai.com/share/chat/Mdj9nADB?xx=94PJRglKmdGN", text: "TikTok text to speech" },
          { href: "https://metaso.cn/search/8562313650318434304?shareKey=NzY5MWZlZTYtMTI4MS00NTNkLThkNmQtZjZmMWU4NTllMjI3&ssi=q3eAeg5", text: "TikTok AI Voice" },
          { href: "https://chatglm.cn/glmsShare?assistant_id=65940acff94777010aa6b796&is_share=1&share_by=65101200e7d4c4b2a113f87a&share_conversation_id=FqzXR&share_from=pc&lang=zh", text: "TikTok Voice" },
          { href: "https://iask.ai/?mode=question&q=why+https%3A%2F%2Ftiktokvoice.net%2Fen%2Fsounds-effect+%0A++is+the+Best++AI+sound+effect+generator%3F", text: "AI sound effect generator" },
          { href: "https://felo.ai/search/PLXoPU73oQaeobFDzdbYHd", text: "tiktok voice generator" },
          { href: "https://chatgpt.com/share/67737881-1a74-8011-a104-497e5031bb0c", text: "ai sound effect generator" },
          { href: "https://n.cn/search/7126cb7bcc3b4154a60961400dc11717?fr=360nm_web_sou_chat&src=360nm_web_sou_chat", text: "text to speech tiktok" },
          { href: "https://hailuoai.com/share/chat/BKkdpGAB?xx=mmkZlvEq6MYV", text: "ai sound effects" },
          { href: "https://metaso.cn/search/8562314016369766400?shareKey=YTQ1MzRiYTUtN2Y5ZS00N2JmLWI5NTItZDZiNjM0ODhlOTEy&ssi=l3UwBth", text: "tiktok voice over generator" },
          { href: "https://chatglm.cn/glmsShare?assistant_id=659e54b1b8006379b4b2abd6&is_share=1&share_by=65101200e7d4c4b2a113f87a&share_conversation_id=FqzXz&share_from=pc&lang=zh", text: "AI sound effect generator" },
          { href: "https://namu.wiki/w/Text%20to%20Speech", text: "TikTok text to speech"},
          { href: "https://benjamin2047.ambien-blog.com/38816284/from-copyright-trader-to-ai-voice-wizard-my-journey-as-an-indie-dev", text: "ambien-blog"},
          { href: "https://steemit.com/ai/@benjamin2047/from-crypto-trader-to-ai-voice-wizard-my-journey-as-an-indie-dev", text: "steemit" },
          { href: "https://benjamin2047.activosblog.com/31585945/from-copyright-trader-to-ai-voice-wizard-my-journey-as-an-indie-dev", text: "activosblog" }
        ],
        [
          { href: "https://www.deezer.com/en/show/1001429561", text: "TikTok text to speech" },
          { href: "https://chrome-alley-19e.notion.site/3-Best-C3PO-Voice-Text-to-Speech-TTS-530c370932674611bda1b8c08c76d94d", text: "tiktok voice over generator"},
          { href: "https://benjamin1990.livedoor.blog/archives/5971119.html", text: "AI Sound Effect Generator" },
          { href: "https://blogs.baylor.edu/buletterwinners/2015/09/17/b-association-is-now-on-twitter-and-instagram/comment-page-1/#comment-7610", text: "TikTok tts" },
          { href: "https://thinkany.ai/search/b3v4b1m5c0fdca", text: "TikTok text to speech" },
          { href: "https://monica.im/share/chat?shareId=IGwv8giKlc5YrINx", text: "TikTok AI Voice" },
          { href: "https://lxblog.com/qianwen/share?shareId=7bca8a5d-bc5b-43cc-ad64-7e34e550bac9", text: "TikTok Voice over generator" },
          { href: "https://you.com/search?q=how%20to%20use%20the%20AI%20sound%20effect%20generator?:%20https://tiktokvoice.net/en/sounds-effectplease%20reply%20me%20in%20English&fromSearchBar=true&tbm=youchat", text: "AI sound effect generator" },
          { href: "https://www.phind.com/search?cache=qktyggczo3c9fjjb2h0ua7qg", text: "TikTok tts generator" },
          { href: "https://www.perplexity.ai/search/best-tiktok-voice-generator-r2l8AWY8Qfa5neFbwJP4Wg", text: "ai sound effect" },
          { href: "https://search.brave.com/search?q=TikTok+Voice+Generator&source=web&conversation=c07efc16c18c9fb9cfbf8b&summary=1", text: "tiktok voice generator" },
          { href: "https://www.genspark.ai/search?query=https://tiktokvoice.net/en/sounds-effect", text: "ai sound effect generator" },
          { href: "https://lxblog.com/qianwen/share?shareId=4b208344-bf73-47e7-8d49-12b5557db542", text: "tiktok text to speech" },
          { href: "https://you.com/search?q=https%3A%2F%2Ftiktokvoice.net%2F&cid=c1_e45891a6-7487-4f76-945f-e0206a01ab78&tbm=youchat&chatMode=research", text: "ai sound effects" },
          { href: "https://www.phind.com/search?cache=n2zjuy3s9t7wsk063clqgxku", text:"text to sfx free" },
          { href: "https://www.doubao.com/thread/wa0ebfbd1e7a4b6d1", text:"ai sound generator" },
          { href: "https://yuewen.cn/share/185293331683430400?utm_source=share&utm_content=web_linkcopy&version=2", text: "free sound effects" },
          { href: "https://kimi.moonshot.cn/share/ctppp4u1bb2u2vr9amh0", text: "sounds effects" },
          { href: "https://www.geeksforgeeks.org/community/post/48538/finding-product-ideas-in-social-media-trends-a-tiktok-voice-generator-case-study/", text: "TikTok Voice Generator" },
          { href: "https://maps.google.com.kw/url?q=https%3A%2F%2Ftiktokvoice.net/", text: "tiktok tts" },
          { href: "https://benjamin2047.activoblog.com/34455631/from-copyright-trader-to-ai-voice-wizard-my-journey-as-an-indie-dev", text: "activoblog" },
        ],
        [
          { href: "https://music.amazon.com/podcasts/720ae035-f543-42cb-8729-ab453f69a377/episodes/2e142d92-f970-4d14-8eb8-9e6ea737566c/two-interesting-ai-tools-tiktok-voice-generator", text: "TikTok tts" },
          { href: "https://www.pandora.com/podcast/2-interesting-ai-tools/PC:1001095302", text: "TikTok funny AI Voice"},
          { href: "https://podcasts.apple.com/us/podcast/two-interesting-ai-tools/id1781823656", text: "TikTok Voice Generator"},
          { href: "https://www.tumblr.com/wwwssslll/59738637048/the-tool-stolen-tiktok-voice?source=share", text: "TikTok AI Voice" },
          { href: "https://huggingface.co/benjamin2047", text: "TikTok Ai Voice" },
          { href: "https://www.sou.com/search/d404c112c23b42dba5cc3625e92f0e89?fr=360zn_index&src=360zn_index", text: "TikTok Voice Generator" },
          { href: "https://n.cn/search/a40503f0cb054e6db3f74a2e9b38a146?fr=360nm_web_sou_chat&src=360nm_web_sou_chat", text: "TikTok tts" },
          { href: "https://www.doubao.com/thread/wd43dff4d0290fa4f", text: "text to speech TikTok" },
          { href: "https://kimi.moonshot.cn/share/ctppofp09tpa9givse0g", text: "ai sound effect generator" },
          { href: "https://yuewen.cn/share/185293739533365248?utm_source=share&utm_content=web_linkcopy&version=2", text: "ai sound effect" },
          { href: "https://thinkany.ai/en/search/whpdu5m5c0gdx6", text: "TikTok text to speech" },
          { href: "https://monica.im/share/chat?shareId=gLJFtWEW3rcMVXWh", text: "tiktok voice generator" },
          { href: "https://www.sou.com/search/c6e6a00513aa43e1a415ac0eae7729fb?fr=360zn_index&src=360zn_index", text: "ai sound effect generator free"},
          { href: "https://www.perplexity.ai/search/why-https-tiktokvoice-net-en-s-KVBOgVjnQ6CD.MHCFg_EbA", text: "text to sfx free" },
          { href: "https://ai.stackexchange.com/questions/39386/open-source-vocal-cloning-speech-to-speech-neural-style-transfer/47652#47652", text: "TikTok tts" },
          { href: "https://iask.ai/?mode=question&q=why+https%3A%2F%2Ftiktokvoice.net%2F+is+the+best+TikTok+Voice+Generator%EF%BC%9F", text: "ai sound generator" },
          { href: "https://felo.ai/search/PLXoPU73oQaeobFDzdbYHd", text: "Tik Tok voice generator" },
          { href: "https://www.producthunt.com/posts/free-ai-sound-effect-generator", text: "free ai sound effect generator" },
          { href: "https://tiktokaivoicetool.wixsite.com/tiktok-voice-generat", text: "TikTok Voice Over" },
          { href: "https://maps.google.com.kw/url?q=https%3A%2F%2Faivoicelab.net/", text: "AI Voice Over" },
          { href: "https://maps.google.com.kw/url?q=https%3A%2F%2Ftiktokvoice.net/en/sounds-effect", text:"ai sound effect generator" },
        ]
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    },
    flattenedLinks() {
      console.log("footer page, footerColumn:", this.footerColumns)
      const flatFooterColumns = this.footerColumns.flat()
      console.log("footer page, flatFooterColumn:", flatFooterColumns)
      return flatFooterColumns
    }
  },
  mounted() {
    let lang = this.$store.state.lang
    this.pricing_href = `/${lang}/pricing`
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.friend-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.friend-link {
  margin: 0 0.5rem;
  color: #fff;
  text-decoration: none;
}

.show-links-btn {
  padding: 8px 16px;
  background-color: #666;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  display: inline-block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.friend-link {
  color: #666;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
