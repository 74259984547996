<template>
<div>
  <nav class="header-nav">
    <div class="ph-header-first">
      <div class="logo_div">
        <a  href="/" title="tik tok voice logo" aria-label="TikTok AI Voice" class="logo-link">
          <img src="/ssr/img/tiktok_voice_logo.svg" title="TikTok text to speech" alt="TikTok tts logo" height="40px" width="50px" class="top_left_logo" />
          <img src="/ssr/img/tiktok_voice_word.svg" loading="lazy" title="TikTok AI Voice Generator" alt="TikTok AI Voice Generator logo" height="40px" width="120px" class="top_left_logo"/>
        </a>
      </div>
      <div v-if="isMobile" class="lg-hidden">
        <button type="button" class="ph-menu-button" aria-label="TikTok tts menu" @click="toggleMenu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" style="width: 24px; height: 24px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5">
            </path>
          </svg>
        </button>
      </div>
    </div>
    <ul class="main-nav" id="js-menu" v-show="!isMobile">
      <li><a class="nav-links" title="TikTok Voice Generator" :href="home_href">{{ $t('comm.home') }}</a></li>
      <li><a class="nav-links" title="TikTok Sounds Effect" :href="sounds_href">{{ $t('comm.sounds') }}</a></li>
      <li><a class="nav-links" title="TikTok AI Voice Clone" :href="voice_clone_href">{{ $t('comm.voice_cloning') }}</a></li>
      <li><a class="nav-links" title="TikTok Text to Speech API" :href="api_href">{{ $t('comm.api_intro') }}</a></li>
      <li class="pricing-nav-item">
        <a class="nav-links" title="TikTok tts Pricing" :href="pricing_href" rel="nofollow">
          <span class="pricing-text">{{ $t('comm.pricing') }}</span>
        </a>
      </li>
      <li><a class="nav-links" title="About TikTok Voice Generator" :href="about_href">{{ $t('comm.about_us') }}</a></li>
    </ul>
    <div class="nav-right" v-show="!isMobile">
      <div class="mobile-divider"></div>
      <label class="nav-right-label" aria-label="tik tok voice generater language">
        <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tiktok tts" v-bind:placeholder="$t('comm.select_language')">
          <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
        </select>
      </label>
      <div v-if="!isLoggedIn">
           <button class="sign-in-btn" v-on:click="showSignIn"> {{ $t('comm.login') }} </button>
      </div>
      <div class="user_menu" v-else>
        <button class="user_button" @click="toggleUserMenu">
          <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
          <span class="user_name"> {{  currentUser.name }}</span>
          <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        <div class="dropdown-menu" id="pcUserMenu" v-show="isUserMenuOpen" >
           <a title="tiktok text to speach settings" :href="settings_href">{{ $t('setting.setting') }}</a>
           <a title="tiktok text to speach logout" href="#" @click="logout">{{ $t('comm.logout') }}</a>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-menu-overlay" v-show="isMobile && isMenuOpen" @click="toggleMenu"> </div>
  <div class="mobile-menu" :class="{ 'open': isMobile && isMenuOpen, 'instant-close': isInstantClose }">
      <ul class="main-nav">
        <li class="nav-item">
          <a class="nav-links" title="TikTok Voice Generator" :href="home_href" @click.prevent="navigateAndCloseMenu('home')">
            {{ $t('comm.home') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok Sounds Effect" :href="sounds_href" @click.prevent="navigateAndCloseMenu('sounds')">
            {{ $t('comm.sounds') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok AI Voice Clone" :href="voice_clone_href" @click.prevent="navigateAndCloseMenu('voiceClone')">
            {{ $t('comm.voice_cloning') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok tts API" :href="api_href" @click.prevent="navigateAndCloseMenu('api')">
            {{ $t('comm.api_intro') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <!-- li class="nav-item">
          <a class="nav-links" title="TikTok tts Pricing" :href="pricing_href" @click.prevent="navigateAndCloseMenu('pricing')">
            <span class="pricing-text">{{ $t('comm.pricing') }}</span>
            <span class="nav-arrow">›</span>
          </a>
        </li -->
        <li class="nav-item">
          <a class="nav-links" title="About TikTok Voice Generator" :href="about_href" @click.prevent="navigateAndCloseMenu('about')">
            {{ $t('comm.about_us') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
      </ul>
      <div class="nav-right">
        <div class="mobile-divider"></div>
        <label class="nav-right-label">
          <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tiktok voice generator" v-bind:placeholder="$t('comm.select_language')">
            <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
          </select>
        </label>
        <div v-if="!isLoggedIn">
             <button class="sign-in-btn" v-on:click="showSignIn">{{ $t('comm.login') }}</button>
        </div>
        <div class="user_menu" v-else @click.stop>
          <button class="user_button" @click.stop="handleUserMenuInteraction" @touchstart.prevent="handleUserMenuInteraction">
            <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
            <span class="user_name"> {{  currentUser.name }}</span>
            <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                 <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <div class="dropdown-menu" id="userMenu"  v-show="isUserMenuOpen" @click.stop style="z-index: 1000;">
             <a title="tiktok tts settings" :href="settings_href">{{ $t('setting.setting') }}</a>
             <a title="tiktok text to speech logout" href="#" @click.stop="logout">{{ $t('comm.logout') }}</a>
          </div>
        </div>
      </div>
  </div>
  <GoogleSignInModal 
      :visible.sync="isSignInVisible"
      @credential-response="handleCredentialResponse"
    />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadLanguageAsync } from '../lang';
import { handleGoogleAuth } from '../utils/auth'
import { reportError } from '../utils/errorReporter'
import debounce from 'lodash/debounce';
import { LANGUAGE_OPTIONS } from '../utils/languageOptions';
import { setCookie, delCookie, getCookie } from '../utils/cookies';

export default {
  name: 'VoiceHeader',
  components: {
    GoogleSignInModal: () => import('./GoogleSignInModal.vue')
  },
  data () {
    return {
      isSignInVisible: false,
      isMobile: true,
      isMenuOpen: false,
      value: 'en', // 默认语言
      home_href: '/',
      pricing_href: '/en/pricing',
      settings_href: '/en/settings',
      isUserMenuOpen: false,
      debouncedCheckMobile: null,
      isInstantClose: false,
      debouncedToggleUserMenu: null,
      voice_clone_href: '/en/voice-cloning',
      sounds_href: '/en/sounds-effect',
      api_href: '/en/api-intro',
      about_href: '/en/about',
      resizeObserver: null,
      host: 'https://tiktokvoice.net'
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser']),
    languageOptions() {
      return LANGUAGE_OPTIONS
    }
  },
  methods: {
    async changeLang (evt) {
      var lang = evt.target.value
      setCookie('locale', lang)
      this.value = lang
      const currentPath = this.$route.path
      const pathParts = currentPath.split('/').filter(Boolean)
      const availableLocales = this.languageOptions.map(option => option.value)

      var newPath
      if (pathParts.length <= 1) {
        if (availableLocales.includes(pathParts[0])) {
          newPath = lang === 'en' ? '/' : `/${lang}`
        } else if (pathParts[0]) {
          newPath = currentPath
        } else {
          // 如果没有值，使用新语言
          newPath = lang === 'en' ? '/' : `/${lang}`
        }
      } else {
        if (availableLocales.includes(pathParts[0])) {
          // 如果第一部分是语言，直接替换
          newPath = currentPath.replace(pathParts[0], lang)
        } else {
          // 如果第一部分不是语言，保留原值并在前面添加新语言（除非是英语）
          newPath =  currentPath
        }
      }
      window.location.href = newPath
    },
    navigateTo(destination) {
      var targetPath;
      switch(destination) {
        case 'home':
          targetPath = this.home_href;
          break;
        case 'pricing':
          targetPath = this.pricing_href;
          break;
        case 'voiceClone':
          targetPath = this.voice_clone_href;
          break;
        case 'sounds':
          targetPath = this.sounds_href;
          break
        case 'api':
          targetPath = this.api_href;
          break;
        case 'about':
          targetPath = this.about_href;
          break;
      }
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            reportError(err, 'navigateTo');
          }
        });
      }
      if (this.isMobile) {
        this.isMenuOpen = false;
      }
    },
    handleUserMenuInteraction(event) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleUserMenu();
    },
    updateLinks(lang) {
      this.pricing_href = `/${lang}/pricing`
      this.settings_href = `/${lang}/settings`
      this.voice_clone_href = `/${lang}/voice-cloning`
      this.sounds_href = `/${lang}/sounds-effect`
      this.api_href = `/${lang}/api-intro`
      this.about_href = `/${lang}/about`
      this.home_href = lang === 'en' ? '/' : `/${lang}`
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    checkMobile() {
      requestAnimationFrame(() => {
        this.isMobile = window.innerWidth <= 900; // 和样式保存一致
        this.isMenuOpen = false;
      });
    },
    showSignIn: function () {
      this.isSignInVisible = true
    },
    closeSignIn: function () {
      this.isSignInVisible = false
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    closeUserMenu() {
      this.isUserMenuOpen = false;
    },
    // toggleUserMenu 的实际现
    actualToggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    async handleCredentialResponse(response) {
      const apiUrl = this.host + "/lapi/auth/google"
      const success = await handleGoogleAuth.handleCredentialResponse(response, this.$store, apiUrl)
      this.closeSignIn()
      if (!success) {
        this.$emit('login-error', 'Authentication failed')
      }
    },
    async logout() {
      await handleGoogleAuth.logout(this.$store)
      // 重新加载页面或执行其他UI相关操作
      this.isSignInVisible = false;
      location.reload();
    },
    navigateAndCloseMenu(destination) {
      this.isInstantClose = true;
      this.isMenuOpen = false;
      this.$nextTick(() => {
        this.navigateTo(destination);
        // 重置 isInstantClose，为下次打开做准备
        setTimeout(() => {
          this.isInstantClose = false;
        }, 50);
      });
    },
  },
  mounted () {
    this.checkMobile()
    this.debouncedCheckMobile = debounce(this.checkMobile, 50,);
    this.debouncedToggleUserMenu = debounce(this.actualToggleUserMenu, 50); // 对 toggleUserMenu 进行防抖处理

    // 使用 ResizeObserver 替代 resize 事件
    this.resizeObserver = new ResizeObserver(this.debouncedCheckMobile);
    this.resizeObserver.observe(document.body);
    // window.addEventListener('click', this.closeUserMenu);

    this.value = this.$store.state.lang
    this.updateLinks(this.$store.state.lang)
  },
  beforeUnmount() {
    // 清理 ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    // 清理防抖函数
    if (this.debouncedCheckMobile) {
      this.debouncedCheckMobile.cancel();
    }
    if (this.debouncedToggleUserMenu) {
      this.debouncedToggleUserMenu.cancel();
    }
  }
}

</script>

<style scoped>
</style>
